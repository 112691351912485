import { Carousel, Col, Row } from "antd";
import React, { useState } from "react";
import CardItem from "../../../base/cardItem";
import OpenInNew from "../../../base/icon/open-in-new";
import B1 from "../../../base/text/b1";
import H3 from "../../../base/text/h3";
import styles from './index.module.css';
import { useWindowSize } from "../../../../hooks/useWindowSize";
import ChevronLeft from "../../../base/icon/chevronLeft";
import i18n from "../../../../language/i18n";
import { App } from "../../../../model/app/App";

export interface PlmjAppsProps {
    apps?: Array<App>
}

function PlmjApps({ apps }: PlmjAppsProps) {
    const [currentSlide, setCurrentSlide] = useState(0);
    const { isPhone, isTablet } = useWindowSize();

    function renderHeader() {
        return (
            <Row className={styles.plmjAppsHeader} >
                <Col>
                    <H3 className={styles.plmjAppsHeaderTitle}>{i18n.t("apps.title")}</H3>
                </Col>
            </Row>
        )
    }

    function renderApps() {
        if (apps === undefined) {
            return
        }

        const itemsLength = apps.length;

        return (
            <Carousel
                className={styles.homepageCarousel}
                slidesToShow={isPhone ? 1 : isTablet ? 2 : 4}
                slidesToScroll={isPhone ? 1 : isTablet ? 2 : 4}
                infinite={false}
                arrows={true}
                dots={false}
                draggable={true}
                afterChange={(currentSlide: number) => setCurrentSlide(currentSlide)}
                nextArrow={(isTablet ? 2 + currentSlide !== itemsLength : 4 + currentSlide !== itemsLength) ? <div id={isTablet ? "mobileCarouselArrowRight" : "desktopCarouselArrowRight"}><ChevronLeft className={styles.arrowNext} /></div> : <div />}
                prevArrow={(currentSlide !== 0) ? <div id={isTablet ? "mobileCarouselArrowLeft" : "desktopCarouselArrowLeft"}><ChevronLeft className={styles.arrowPrev} /></div> : <div />}
            >
                {apps?.map((app: App) => ((
                    <Col xs={24} md={22} lg={20} className={styles.appCardContainer}>
                        <a href={app?.link} target="_blank" rel="noreferrer" className={[styles.appCardLink, "noDecoration"].join(" ")}>
                            <CardItem className={styles.appCard} cover={app?.cover} coverClassName={styles.appCardCover} bodyStyle={{ padding: 'unset', marginTop: "35px", display: "flex", justifyContent: "center" }}>
                                <Row className={styles.plmjAppsSubtitle} wrap={false}>
                                    <B1 className={styles.cardTitle} >{app?.title}</B1>
                                    <div className={styles.plmjAppsOpenInNew}><OpenInNew /></div>
                                </Row>
                            </CardItem>
                        </a>
                    </Col>
                )))}
            </Carousel>
        )
    }

    return (
        <Row>
            <Col className={styles.contentContainer}>
                {renderHeader()}
                {renderApps()}
            </Col>
        </Row>
    )
}

export default PlmjApps;